@import-normalize;
@import "./helpers/Variables.scss";
@import "./helpers/Functions.scss";


body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: $default-text-color;
  &.noScroll{
    overflow: hidden;
  }
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

section.Wrapper ul,
section.Wrapper li {
  margin: 0 0 0 em(18);
  padding: auto;
  list-style-type: disc;
  list-style-position: outside;

}

p {
  margin-top: 0;
}

html,
body,
#root,
.App {
  min-height: 100vh;
}

.AtFlex {
  display: flex;
  align-items: center;
  justify-content: center;

  &.XSpaceBtw {
    justify-content: space-between;
  }

  &.XStart {
    justify-content: flex-start;
  }

  &.XEnd {
    justify-content: flex-end;
  }

  &.XSpaceEvenly {
    justify-content: space-evenly;
  }

  &.XBetween {
    justify-content: space-between;
  }

  &.YStart {
    align-items: flex-start;
  }

  &.YEnd {
    align-items: flex-end;
  }

  &.YStrech{
    align-items: stretch;
  }
}

.Wrapper {
  max-width: 1600px;
  width: 96%;
  margin: auto;
}

.Wrapper-750 {
  max-width: 750px;
}

.Wrapper-1200 {
  max-width: 1200px;
}

.Wrapper-1240 {
  max-width: 1240px;
}

.Wrapper-Fluid {
  padding: 0 em(20);
}

section.Wrapper {
  padding: 90px 0;
}

// section.Wrapper.Partners{
//     padding-bottom: 0;
// }

.SecDesc {
  font-size: em(20);
  color: $text-color-desc;
}

.Center {
  text-align: center;
}

.BtnBox {
  margin-top: 30px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.borderTop {
    border-top: 1px solid #d4d4d4;
}

.borderBottom {
    border-bottom: 1px solid #d4d4d4;
}
@media screen and (min-width: 969px) {
  body {
    overflow-x: hidden;
  }

  .Wrapper-Fluid {
    // padding: 0 em(70);
    padding: 0 70px;

  }
}

@media screen and (max-width: 600px) {
    .SecDesc {
        font-size: em(18);
      }
      section.Wrapper {
        padding-left: em(15);
        padding-right: em(15);
        padding-bottom: em(30);
        padding-top: em(70);
      }
      section .Wrapper{
        padding-left: em(15);
        padding-right: em(15);
      }
      h2.SectionTitle{
        margin-bottom: em(10);
      }

      section.Wrapper.Partners{
        padding-bottom: em(70);
      }
      section.firstSection{
        padding-top: 140px;
      }
}

html, body {
    overscroll-behavior-x: none;
  }



