@use 'sass:math';

@mixin position-center($text-align: center) {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	text-align: $text-align;
}

.loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fafafa;
	z-index: 9999;
}

.loading-text {
	@include position-center;
	width: 100%;
	height: 100px;
	line-height: 100px;
	span {
		display: inline-block;
		margin: 0 5px;
		color: rgb(165, 165, 165);

		@for $i from 0 through 11 {
			&:nth-child(#{$i + 1}) {
				filter: blur(0px);
				animation: blur-text 1.2s (#{math.div($i,11)})+s infinite linear alternate;
			}
		}
	}
}

@keyframes blur-text {
	0% {filter: blur(0px);}
	100% {filter: blur(3px);}
}